<template>
  <div class="become-author">
    <div class="become-author__container">
      <h1 class="become-author__title">
        {{ _T("@Blog become an author title") }}
      </h1>
      <div
        class="become-author__conditions ui-rich-text"
        v-html="_T('@Blog become an author conditions')"
      />
      <form class="become-author__form">
        <div class="become-author__form-container">
          <div class="become-author__form-block">
            <InputText
              v-model="form.name"
              :validate="v.name"
              :maxlength="255"
              :caption="_T('@Name')"
              autocomplete-type="given-name"
              :mobile-max-width="true"
            />
            <InputEmail
              v-model="form.email"
              :validate="v.email"
              caption="E-mail"
              :mobile-max-width="true"
            />
          </div>
          <InputTextarea
            v-model="form.comment"
            :validate="v.comment"
            :maxlength="10240"
            :caption="_T('@Your comment')"
            :required="false"
          />
          <BaseCheckbox
            v-model="form.isAgreementAccepted"
            :validate="v.isAgreementAccepted"
          >
            <span>
              {{ _T("@I agree with") }}
              <MyLink name="privacy-policy" target="_blank">
                {{ _T("@privacy policy") }}
              </MyLink>
            </span>
          </BaseCheckbox>
          <ButtonPrimary
            type="submit"
            :aria-label="_T('@Send')"
            button-width="262px"
            :disabled="sendForm.isHandled"
            @click.prevent="onSubmit"
          >
            {{ _T("@Send") }}
          </ButtonPrimary>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import InputText from "~/modules/shared/inputs/InputText.vue";
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import InputTextarea from "~/modules/shared/inputs/InputTextarea.vue";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";
import { modalFormStore } from "~/modules/shared/modals/store/modalFormStore";
import { useUserStore } from "~/store/userStore";
import {
  emailValidate,
  maxLengthValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import useVuelidate from "@vuelidate/core";
import { useSingletonHandler } from "~/uses/useSingletonHandler";
import { useAPI } from "~/uses/useMyFetch";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";

const modalStore = useModalStore();
const formStore = modalFormStore();
const userStore = useUserStore();
const { currentUser: user, userFullName } = storeToRefs(userStore);

onMounted(() => {
  if (null !== user.value) {
    form.name = userFullName.value;
    form.email = user.value.email;
  }

  formStore.addForm({ v, requestOptions, id: "becomeAuthor" });
});

const successfulData = {
  title: _T("@Successful modal title"),
  description: _T("@Successful modal text"),
  isWithTimeout: true,
};

const form = reactive({
  name: "",
  email: "",
  comment: "",
  isAgreementAccepted: true,
});

const rules = computed(() => ({
  name: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  comment: {},
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

const onSubmit = async () => {
  if (!formStore.isFormsReady()) {
    return;
  }

  return await sendForm.handle();
};

const requestOptions = () => {
  return {
    event: "becomeBlogAuthor",
    name: form.name,
    email: form.email,
    isConfirmed: true,
    form: {
      comment: form.comment,
    },
  };
};

const sendForm = useSingletonHandler(() =>
  useAPI("/marketing/event", {
    method: "POST",
    body: {
      ...requestOptions(),
    },
  }).then(() => {
    form.name = "";
    form.email = "";
    form.comment = "";

    modalStore.toggleModal(successfulModal, successfulData);
  }),
);

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style scoped lang="scss">
.become-author {
  @extend %padding-wrp;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start, center);
    gap: 24px;

    background-color: white;
    border-radius: 16px;

    padding: 16px 24px;
    margin-inline: auto;

    @include mobile {
      gap: 16px;

      padding-inline: 8px;
    }
  }

  &__title {
    max-width: 850px;
    width: 100%;

    @include font(34, 40, 600);
    text-align: center;

    @include mobile {
      @include font(22, 30, 600);
    }
  }

  &__conditions {
    max-width: 1200px;
    width: 100%;
  }

  &__form {
    max-width: 850px;
    width: 100%;

    background-color: var(--color-sky-lightest);
    border-radius: 30px;

    padding: 24px 16px;
  }

  &__form-container {
    max-width: 690px;
    width: 100%;

    @include flex-container(column, flex-start, center);
    gap: 24px;

    margin-inline: auto;

    @include mobile {
      gap: 16px;
    }
  }

  &__form-block {
    width: 100%;

    @include flex-container(row, space-between, center);
    gap: 16px;

    @include mobile {
      flex-direction: column;
    }
  }
}
</style>
